// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as H1 from "../../../styleguide/components/Heading/H1.res.js";
import * as Api from "../../../api/Api.res.js";
import * as Url from "../../../utils/Url.res.js";
import * as Form from "../../../styleguide/forms/Form/Form.res.js";
import * as User from "../../../models/User.res.js";
import * as Hooks from "../../../libs/Hooks.res.js";
import * as Label from "../../../styleguide/forms/Label/Label.res.js";
import * as Button from "../../../styleguide/components/Button/Button.res.js";
import * as $$Promise from "../../../bindings/Promise.res.js";
import * as HelpBlock from "../help-block/HelpBlock.res.js";
import * as TextField from "../../../styleguide/forms/TextField/TextField.res.js";
import * as SignInData from "./SignInData.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as SentryLogger from "../../../loggers/SentryLogger.res.js";
import * as SignInScss from "./SignIn.scss";
import * as SignInEmailError from "./errors/SignInEmailError.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as SignInPasswordError from "./errors/SignInPasswordError.res.js";

var css = SignInScss;

var initialState_input = SignInData.Input.empty();

var initialState = {
  isSubmitted: false,
  input: initialState_input,
  results: undefined,
  serverError: undefined
};

function SignIn$default(props) {
  var setUserData = props.setUserData;
  var match = Hooks.useReducer(initialState, (function (state, action) {
          if (typeof action === "object") {
            if (action.TAG === "Update") {
              return {
                      TAG: "Update",
                      _0: {
                        isSubmitted: state.isSubmitted,
                        input: action._0,
                        results: state.results,
                        serverError: state.serverError
                      }
                    };
            } else {
              return {
                      TAG: "UpdateWithSideEffects",
                      _0: {
                        isSubmitted: state.isSubmitted,
                        input: state.input,
                        results: state.results,
                        serverError: action._0
                      },
                      _1: (function (param) {
                          param.dispatch("AfterSubmit");
                        })
                    };
            }
          }
          switch (action) {
            case "Validate" :
                return {
                        TAG: "Update",
                        _0: {
                          isSubmitted: state.isSubmitted,
                          input: state.input,
                          results: SignInData.Validate.all(state.input),
                          serverError: state.serverError
                        }
                      };
            case "Send" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          isSubmitted: state.isSubmitted,
                          input: state.input,
                          results: SignInData.Validate.all(state.input),
                          serverError: state.serverError
                        },
                        _1: (function (param) {
                            var dispatch = param.dispatch;
                            var state = param.state;
                            if (SignInData.Validate.valid(Belt_Option.getExn(state.results))) {
                              return $$Promise.wait(Api.signIn(state.input), (function (x) {
                                            if (x.TAG === "Ok") {
                                              var res = x._0;
                                              if (res.TAG === "UserObject") {
                                                Url.visit("/");
                                              } else {
                                                var data = res._0;
                                                setUserData(User.toJs(data.user));
                                                Url.visit(data.afterSignInPath);
                                              }
                                              return dispatch({
                                                          TAG: "ServerError",
                                                          _0: undefined
                                                        });
                                            }
                                            var match = x._0;
                                            if (typeof match === "object") {
                                              var error = match.VAL;
                                              SentryLogger.error1({
                                                    rootModule: "SignIn",
                                                    subModulePath: /* [] */0,
                                                    value: "default",
                                                    fullPath: "SignIn.default"
                                                  }, "Send::Error::Message", [
                                                    "Error",
                                                    error
                                                  ]);
                                              return dispatch({
                                                          TAG: "ServerError",
                                                          _0: error
                                                        });
                                            }
                                            SentryLogger.error({
                                                  rootModule: "SignIn",
                                                  subModulePath: /* [] */0,
                                                  value: "default",
                                                  fullPath: "SignIn.default"
                                                }, "Send::Error::Opaque");
                                            dispatch({
                                                  TAG: "ServerError",
                                                  _0: "Something went wrong"
                                                });
                                          }));
                            } else {
                              return dispatch("AfterSubmit");
                            }
                          })
                      };
            case "AfterSubmit" :
                return {
                        TAG: "Update",
                        _0: {
                          isSubmitted: false,
                          input: state.input,
                          results: state.results,
                          serverError: state.serverError
                        }
                      };
            case "BeforeSubmit" :
                return {
                        TAG: "UpdateWithSideEffects",
                        _0: {
                          isSubmitted: true,
                          input: state.input,
                          results: state.results,
                          serverError: state.serverError
                        },
                        _1: (function (param) {
                            param.dispatch("Send");
                          })
                      };
            
          }
        }));
  var dispatch = match[1];
  var state = match[0];
  var error = state.serverError;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("div", {
                    children: JsxRuntime.jsxs("div", {
                          children: [
                            JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(H1.make, {
                                          children: "Sign In"
                                        }),
                                    props.invalidInvitation ? JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx("div", {
                                                  children: JsxRuntime.jsx("p", {
                                                        children: "This invitation is no longer active. Please sign into Datacenters.com."
                                                      }),
                                                  className: css.info
                                                }),
                                            className: Cx.cx([
                                                  css.messageContainer,
                                                  css.info
                                                ])
                                          }) : null,
                                    JsxRuntime.jsxs("div", {
                                          children: [
                                            "Don't have an account? ",
                                            JsxRuntime.jsx("a", {
                                                  children: "Sign Up",
                                                  href: "/users/sign_up"
                                                })
                                          ],
                                          className: css.toSignUp
                                        }),
                                    JsxRuntime.jsxs(Form.make, {
                                          id: "new-user",
                                          className: css.loginForm,
                                          onSubmit: (function (param) {
                                              dispatch("BeforeSubmit");
                                            }),
                                          children: [
                                            error !== undefined ? JsxRuntime.jsx("div", {
                                                    children: JsxRuntime.jsx("p", {
                                                          children: error
                                                        }),
                                                    className: css.error
                                                  }) : null,
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx(Label.make, {
                                                          forId: "sign-in--email",
                                                          children: "Email"
                                                        }),
                                                    JsxRuntime.jsx(TextField.make, {
                                                          id: "sign-in--email",
                                                          value: state.input.email,
                                                          status: Belt_Option.map(state.results, (function (x) {
                                                                  if (x.email.TAG === "Ok") {
                                                                    return "Valid";
                                                                  } else {
                                                                    return "Error";
                                                                  }
                                                                })),
                                                          onChange: (function ($$event) {
                                                              var init = state.input;
                                                              dispatch({
                                                                    TAG: "Update",
                                                                    _0: {
                                                                      email: $$event.target.value,
                                                                      password: init.password
                                                                    }
                                                                  });
                                                            })
                                                        }),
                                                    JsxRuntime.jsx(SignInEmailError.make, {
                                                          results: state.results
                                                        })
                                                  ],
                                                  className: css.fieldRow
                                                }),
                                            JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx(Label.make, {
                                                          forId: "sign-in--password",
                                                          children: "Password"
                                                        }),
                                                    JsxRuntime.jsx(TextField.make, {
                                                          id: "sign-in--password",
                                                          type_: "Password",
                                                          value: state.input.password,
                                                          status: Belt_Option.map(state.results, (function (x) {
                                                                  if (x.password.TAG === "Ok") {
                                                                    return "Valid";
                                                                  } else {
                                                                    return "Error";
                                                                  }
                                                                })),
                                                          onChange: (function ($$event) {
                                                              var init = state.input;
                                                              dispatch({
                                                                    TAG: "Update",
                                                                    _0: {
                                                                      email: init.email,
                                                                      password: $$event.target.value
                                                                    }
                                                                  });
                                                            })
                                                        }),
                                                    JsxRuntime.jsx(SignInPasswordError.make, {
                                                          results: state.results
                                                        })
                                                  ],
                                                  className: css.fieldRow
                                                }),
                                            JsxRuntime.jsx(Button.make, {
                                                  size: "MD",
                                                  color: "Primary",
                                                  busy: state.isSubmitted,
                                                  submit: true,
                                                  className: css.submitButton,
                                                  children: "Sign In"
                                                })
                                          ]
                                        }),
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx("a", {
                                                children: "Forgot password?",
                                                href: "/users/password/new"
                                              }),
                                          className: css.toForgotPassword
                                        })
                                  ],
                                  className: Cx.cx([
                                        css.column,
                                        css.signIn
                                      ])
                                }),
                            JsxRuntime.jsx("div", {
                                  children: JsxRuntime.jsx(HelpBlock.make, {}),
                                  className: Cx.cx([
                                        css.column,
                                        css.helpContainer
                                      ])
                                })
                          ],
                          className: css.row
                        }),
                    className: css.container
                  }),
              className: css.signInContainer
            });
}

var Input;

var Validate;

var ValidationResult;

var $$default = SignIn$default;

export {
  css ,
  Input ,
  Validate ,
  ValidationResult ,
  initialState ,
  $$default as default,
}
/* css Not a pure module */
